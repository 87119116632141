









import { Component, Vue } from 'vue-property-decorator'

import NavMenu from '@/partials/components/NavMenu.vue'
// import PERMISSIONS from '@/helpers/permissions'

@Component({
  components: {
    NavMenu
  }
})
export default class MedicalMenuLayout extends Vue {
  availableRoutes: AvailableRoute[] = []

  created () {
    this.availableRoutes = [
      {
        label: 'Pacientes',
        path: '/medical/patients'
      },
      {
        label: 'Questionários',
        path: '/medical/forms'
      },
      {
        label: 'Orientações',
        path: '/medical/instructions'
      }
    ]
  }
}
